import {useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { GENDERDATA, POSITIONS,  } from '../constants/data';
import { generateRandomPassword } from '../Helper/GenerateRandomPassword';
import useAuth from '../hooks/useAuth';
import { db } from '../firebase'; 
import { collection, addDoc } from "firebase/firestore";
import emailjs from 'emailjs-com';

const columns = [
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'email', label: 'Email', minWidth: 100 },
  {
    id: 'position',
    label: 'Position',
    minWidth: 150,
  },
  {
    id: 'subsalesTier',
    label: 'Subsale Tier',
    minWidth: 150,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'projectTier',
    label: 'Project Tier',
    minWidth: 150,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'joinedDate',
    label: 'Joined Date',
    minWidth: 150,
    format: (value) => new Date(value).toLocaleString(),
  },
];

function createData(name, email, position, subsalesTier, projectTier, joinedDate) {
  return {name, email, position, subsalesTier, projectTier, joinedDate};
}

const rows = [
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
];

const initialRows = [
  createData('Ivan Lim', 'ivanlim608@gmail.com', 'Team Executive', 70, 70, 1698316800000),
  // Additional initial data can be added here if needed
];

export default function UsersPage() {
  const [rows, setRows] = useState(initialRows);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const { user: currentUser } = useAuth();

  // Safely access currentUser.uid
  if (currentUser) {
    console.log(currentUser.uid);
  } else {
    console.log('User is not authenticated');
  }

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: generateRandomPassword(),
    gender: GENDERDATA[0],
    position: POSITIONS[0],
    ic: "",
    contact: "",
    joinedDate: "",
    recruiterId: currentUser ? currentUser.uid : "",
    recruiterName: currentUser ? currentUser.displayName || "" : "",
    downline: [],
    insertedAt: new Date(),
    updatedAt: new Date(),
    updatedBy: currentUser ? currentUser.displayName || "" : "",
    salary: 0,
    medLeaves: 0,
    annualLeaves: 0,
    subsalesTier: 70,
    projectTier: 0,
  })

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewUser({
      name: "",
      email: "",
      password: generateRandomPassword(),
      gender: GENDERDATA[0],
      position: POSITIONS[0],
      ic: "",
      contact: "",
      joinedDate: "",
      recruiterId: currentUser ? currentUser.uid : "",
      recruiterName: currentUser ? currentUser.displayName || "" : "",
      downline: [],
      insertedAt: new Date(),
      updatedAt: new Date(),
      updatedBy: currentUser ? currentUser.displayName || "" : "",
      salary: 0,
      medLeaves: 0,
      annualLeaves: 0,
      subsalesTier: 70,
      projectTier: 0,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async () => {
    try {
      // Save the new user data to Firestore
      const docRef = await addDoc(collection(db, "users"), {
        ...newUser,
        joinedDate: newUser.joinedDate ? new Date(newUser.joinedDate).getTime() : null,
        insertedAt: new Date(),
        updatedAt: new Date(),
      });

      console.log("Document written with ID: ", docRef.id);

      // Send email notification to the new user
      await sendEmail(newUser);

      // Update the local state
      setRows([...rows, { ...newUser, id: docRef.id }]);
      handleClose();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sendEmail = async (userDetails) => {
    const templateParams = {
      from_name: "Admin, Ivan",
      from_email: "ivanlim608@gmail.com", // Replace with your admin email
      subject: "Welcome to Our Platform",
      message: `Hi ${userDetails.name}, your account has been successfully created. Your initial password is ${userDetails.password}. Please login to https://application.ivanlimchoonkiat.com/ using your email and initial password`,
      to_name: userDetails.name,
      to_email: userDetails.email,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      console.log('Email sent successfully!');
    } catch (error) {
      console.error('Email sending error:', error);
    }
  };
  
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Button variant="contained" color="primary" onClick={handleOpen} sx={{ margin: 2 }}>
        Add User
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            fullWidth
            value={newUser.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="IC"
            name="ic"
            fullWidth
            value={newUser.ic}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Contact"
            name="contact"
            fullWidth
            value={newUser.contact}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Gender"
            name="gender"
            select
            fullWidth
            value={newUser.gender}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            {GENDERDATA.map((gender) => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Position"
            name="position"
            select
            fullWidth
            value={newUser.position}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            {POSITIONS.map((position) => (
              <option key={position} value={position}>
                {position}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Joined Date"
            name="joinedDate"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newUser.joinedDate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Salary"
            name="salary"
            type="number"
            fullWidth
            value={newUser.salary}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Medical Leaves"
            name="medLeaves"
            type="number"
            fullWidth
            value={newUser.medLeaves}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Annual Leaves"
            name="annualLeaves"
            type="number"
            fullWidth
            value={newUser.annualLeaves}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="warning">
            Cancel
          </Button>
          <Button onClick={handleAddUser} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
