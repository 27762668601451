import {useState} from 'react';
import TabPanel from '../components/TabPanel';
import { Tab, Box, Tabs } from '@mui/material';

const DocumentsPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        centered
      >
        <Tab label="Users" />
        <Tab label="Sign-in method" />
        <Tab label="Templates" />
        <Tab label="Usage" />
        <Tab label="Settings" />
        <Tab label="Extensions" />
      </Tabs>

      <TabPanel value={value} index={0}>
        Users Content
      </TabPanel>
      <TabPanel value={value} index={1}>
        Sign-in method Content
      </TabPanel>
      <TabPanel value={value} index={2}>
        Templates Content
      </TabPanel>
      <TabPanel value={value} index={3}>
        Usage Content
      </TabPanel>
      <TabPanel value={value} index={4}>
        Settings Content
      </TabPanel>
      <TabPanel value={value} index={5}>
        Extensions Content
      </TabPanel>
    </Box>
  );
}

export default DocumentsPage