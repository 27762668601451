const POSITIONS = [
    "Team Executive",
    "Pre Leader",
    "Team Leader",
    "Prime Leader",
    "Team Director",
    "Admin"
]

const GENDERDATA = [
   "Male",
   "Female",
   "Others"
]

const SUBSALESTIER = [
    70, 80, 90, 100
]

export {
    POSITIONS,
    GENDERDATA,
    SUBSALESTIER,
}