import React, { useState, useMemo } from 'react';
import { Box, CssBaseline, Drawer, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon, ListItemText, IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { LogoutRounded } from '@mui/icons-material';

const drawerWidth = 240;

const Layouts = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getTitle = (path) => {
    switch (path) {
      case '/':
        return 'Home';
      case '/documents':
        return 'Documents';
      case '/users':
        return 'User';
      default:
        return 'Home';
    }
  };

  const title = useMemo(() => getTitle(location.pathname), [location.pathname]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to the login page after logging out
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const drawer = (
    <div>
        <Toolbar>
            <Typography variant="h6" noWrap>
                LSV System
            </Typography>
        </Toolbar>
        <List>
            {[
            { text: 'Home', icon: <HomeIcon />, link: '/' },
            { text: 'Documents', icon: <DescriptionIcon />, link: '/documents' },
            { text: 'User', icon: <PersonIcon />, link: '/users' },
            ].map((item, index) => (
            <ListItem key={item.text} component={Link} to={item.link}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
            </ListItem>
            ))}
        </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
            position="fixed"
            sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            }}
        >
            <Toolbar>
              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
              >
                  <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                  {title}
              </Typography>
              <Box sx={{ flexGrow: 1 }}/>
              <Button
                color="inherit"
                onClick={handleLogout}
                startIcon={<LogoutRounded />} // Add the icon before the text
                sx={{ 
                  border: '1px solid white', // White border
                  borderRadius: 1, // Optional: for rounded corners
                  padding: '6px 16px', // Optional: adjust padding as needed
                }}
              >
                Logout
              </Button>
            </Toolbar>
        </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layouts;
