import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import DocumentsPage from './pages/DocumentsPage';
import { AuthProvider, useAuth } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/HomePage';
import Layouts from './components/Layouts';
import UsersPage from './pages/UsersPage';

const App = () => {
  const { currentUser } = useAuth();

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes without Layouts */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />

          {/* Routes with Layouts for authenticated users only */}
          {currentUser && (
            <Route
              path="/*"
              element={
                <Layouts>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <HomePage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/documents"
                      element={
                        <PrivateRoute>
                          <DocumentsPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <PrivateRoute>
                          <UsersPage />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </Layouts>
              }
            />
          )}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
